import React, { useEffect, useState } from 'react';
import { IconButton } from "./iconButton.tsx";
import { CloseOutlined } from '@ant-design/icons';
import { useLocation } from "wouter";
import { defaultLocations } from '../../../models/locations.ts';
import axios from "axios";
import { GameDTO } from '../../../models/game.ts';
import { HistoryIcon, LocationIcon } from '@rescui/icons';

interface Props {
  rightButtonType: "history" | "back-to-game" | "none";
  gameName: string;
}

export const Header: React.FC<Props> = (props) => {
  const [, setLocation] = useLocation();
  const { rightButtonType, gameName } = props;
  const [prettyName, setPrettyName] = useState("");

  useEffect(() => {
    (async () => {
      const defaultLocationName = defaultLocations.find(l => l.id === gameName)?.label;
      if (defaultLocationName) {
        setPrettyName(defaultLocationName);
      } else {
        try {
          const response = await axios.get(`/api/games/${gameName}`);
          const data: GameDTO = response.data;
          setPrettyName(data.name);
        } catch (e) {
          setPrettyName(gameName);
        }
      }
    })();
  }, [gameName]);

  let rightButton = <span/>;
  switch (rightButtonType) {
    case "back-to-game": {
      rightButton = <IconButton
          icon={<CloseOutlined />}
          onClick={() => setLocation(`game/${gameName}`)}
      />;
      break;
    }
    case "history": {
      rightButton = <IconButton
          icon={<HistoryIcon size="l" />}
          onClick={() => setLocation(`history/${gameName}`)}
      />;
      break;
    }
  }

  return <nav
    className="h-[100px] pt-[10px] pb-[10px] w-full max-w-[600px] flex-shrink-0 flex flex-row justify-between items-center">
    <button className="font-semibold rounded-full py-2 px-4 text-3xl hover:bg-gray-200 flex flex-nowrap items-center gap-3" onClick={() => setLocation("")}>
      <LocationIcon/> {prettyName}
    </button>
    {rightButton}
  </nav>;

};
