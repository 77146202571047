import { Route, Switch, useLocation } from "wouter";
import { ConfigProvider } from "antd";
import { MainPage } from "./pages/main/mainPage.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AdminPage from "./pages/admin/admin.tsx";
import { useMe } from "./hooks/useMe.ts";
import { GoogleSignIn } from "./components/google-sign-in/GoogleSignIn.tsx";
import { LoadingOutlined } from "@ant-design/icons";

const queryClient = new QueryClient();


function LoginPage({ fromLocation }: { fromLocation: string }) {
  return (
    <div className="bg-bg h-svh flex flex-col justify-center items-center">
      <div className="flex m-4 pl-4 pr-4 place-items-center border shadow rounded-xl">
        <GoogleSignIn successfulUrl={fromLocation} />
      </div>
    </div>
  );
}

function CheckAuth() {
  const [location] = useLocation();
  const { authorized, isLoading } = useMe();

  if (isLoading) {
    return <LoadingOutlined
      className={"absolute top-1/2 left-1/2"}
      size={48}
    />;
  }

  if (!authorized) {
    return <LoginPage fromLocation={location} />;
  }

  return (
    <div className="bg-bg relative mx-auto h-svh p-4 px-[5%] pt-0">
      <Switch>
        <Route path="/admin/:rest*" component={AdminPage} />
        <Route component={MainPage} />
      </Switch>
    </div>
  );
}

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "JetBrains Sans"
          }
        }}
      >
        <CheckAuth />
      </ConfigProvider>
    </QueryClientProvider>
  );
}
