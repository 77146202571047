import React from "react";
import logo from "../../assets/logo.svg";
import { Link } from "wouter";
import { defaultLocations } from '../../models/locations.ts';

export function HomePage() {
    return <div className="flex flex-nowrap flex-col items-center justify-center h-svh text-center">
        <h1 className="text-2xl font-semibold mb-4">SnackMe</h1>
        <img src={logo} className="w-[72px] mb-8" alt="Yumy!"/>
        <h2 className="font-semibold text-4xl my-8">Choose your location</h2>
        <div className="flex flex-col flex-nowrap">
            {defaultLocations.filter(({disabled}) => disabled !== true).map(option => <Link
                key={option.id}
                className="rounded-full bg-transparent hover:bg-gray-200 focus:bg-gray-200 px-16 py-2 text-xl"
                to={`game/${encodeURIComponent(option.id)}`}
            >
                {option.label}
            </Link>)}
            {defaultLocations.filter(l => l.disabled).map(option => <span key={option.id} className="px-16 py-2 text-xl cursor-not-allowed">
                {option.label} (coming soon)
            </span>)}
        </div>
    </div>;

}
