import React, { ReactNode, useState, useEffect } from 'react';
import "./iconButton.css";
import classNames from 'classnames';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: ReactNode;
    onClick(): void;
    label?: string;
    expanded?: boolean;
    reversed?: boolean;
    activeColor?: string;
}

const useHover = () => {
    const [hoverSupported, setHoverSupported] = useState(true);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(hover: hover)');
        setHoverSupported(mediaQuery.matches);

        const handler = (e: MediaQueryListEvent) => {
            setHoverSupported(e.matches);
        };

        mediaQuery.addEventListener('change', handler);
        return () => mediaQuery.removeEventListener('change', handler);
    }, []);

    return hoverSupported;
};

export const IconButton: React.FC<Props> = (props) => {

    const { icon, onClick, label, expanded, reversed, className, activeColor } = props;
    const expandable = label !== undefined && label.trim() !== "";
    const hoverSupported = useHover();
    const [isExpanded, setIsExpanded] = useState(false);

    const classname = classNames(
      `snackme-icon-button rounded-full bg-white hover:bg-gray-200 hover:scale-105 flex flex-nowrap items-center justify-center text-xl ${className ?? ''}`,
      { expanded: expanded || isExpanded, expandable, reversed }
    );

    const handleClick = () => {
        if (!hoverSupported && expandable) {
            setIsExpanded(!isExpanded);
        }
        if (onClick) {
            onClick();
        }
    };

    return (
      <button
        onClick={handleClick}
        className={classname}
        style={{ color: activeColor ?? "black" }}
      >
          <span className="icon">{icon}</span>
          {label && <label className="snackme-icon-button-label text-black">{label}</label>}
      </button>
    );
};
