import React, { useMemo, useState } from "react";
import "./snackCard.css";
import TinderCard from "react-tinder-card";
import { DislikeTwoTone, ForwardFilled, HeartFilled, StarFilled } from "@ant-design/icons";
import { animated, useSpring } from "@react-spring/web";
import { ImageWithSpinner } from "./imageWithSpinner.tsx";
import { IconButton } from "./iconButton.tsx";
import { LightningIcon, ThumbsDownIcon } from "@rescui/icons";

const ANIMATION_DURATION_MS = 500;

interface Props {
    id: string; //UUID
    label: string;
    imageUrl: string;

    onSwipeUp?: () => void;
    onSwipeDown?: () => void;
    onSwipeLeft?: () => void;
    onSwipeRight?: () => void;
}

type Direction = 'left' | 'right' | 'up' | 'down';

export const SnackCard: React.FC<Props> = (props) => {

    const { id, label, imageUrl, onSwipeUp, onSwipeDown, onSwipeLeft, onSwipeRight } = props;

    const [directionByButton, setDirectionByButton] = useState<Direction | null>(null);
    const [preSwipeDirection, setPreSwipeDirection] = useState<Direction | null>(null);

    const awesomeAnimation = useSpring({
        opacity: preSwipeDirection === "up" ? 1 : 0,
        transform: preSwipeDirection === "up" ? 'translateY(0px)' : 'translateY(20px)',
        config: { tension: 170, friction: 20 },
    });

    const cardAnimation = useSpring({
        to: {
            transform: resolveTransform(directionByButton),
            opacity: directionByButton ? 0 : 1,
        },
        config: {
            tension: 200,
            friction: 50,
            duration: ANIMATION_DURATION_MS,
        },
    });

    function onSwipe(direction: Direction): void {
        console.log(`swiped to the ${direction}`);
        switch (direction) {
            case 'up': {
                onSwipeUp && onSwipeUp();
                break;
            }
            case 'down': {
                onSwipeDown && onSwipeDown();
                break;
            }
            case 'left': {
                onSwipeLeft && onSwipeLeft();
                break;
            }
            case 'right': {
                onSwipeRight && onSwipeRight();
                break;
            }
        }
    }

    function handleButtonClick(direction: Direction): void {
        setDirectionByButton(direction);
        setTimeout(() => onSwipe(direction), ANIMATION_DURATION_MS);
    }

    /**
     * We need this useMemo because of the card's onSwipeRequirementFulfilled bug described here:
     * https://github.com/3DJakob/react-tinder-card/issues/151
     */
    const memoizedCard = useMemo(() => <TinderCard
        onSwipe={onSwipe}
        flickOnSwipe={true}
        className="swipe-card"
        swipeRequirementType="position"
        swipeThreshold={150}
        onSwipeRequirementFulfilled={(d) => setPreSwipeDirection(d)}
        onSwipeRequirementUnfulfilled={() => setPreSwipeDirection(null)}
    >
        <ImageWithSpinner id={id} src={imageUrl} label={label}/>
        <div className="absolute inset-0 flex items-center justify-center z-10 text-8xl pointer-events-none">
            <HeartFilled className="absolute top-4 left-6 text-red-500 card-overlay-icon card-overlay-icon-swipe-right" />
            <DislikeTwoTone className="absolute top-4 right-6 card-overlay-icon card-overlay-icon-swipe-left" />
            <StarFilled className="text-[#FFD700] card-overlay-icon card-overlay-icon-swipe-up" />
            <ForwardFilled className="text-white absolute top-4 text-center card-overlay-icon card-overlay-icon-swipe-down" />
        </div>
    </TinderCard>, []);

    return <div className="relative h-full">
        <animated.div style={{ ...cardAnimation }} className={`h-full swipe-${preSwipeDirection ?? directionByButton ?? "none"}`}>
            {memoizedCard}
        </animated.div>

        <div className="absolute bottom-10 flex flex-row justify-center w-full gap-4 items-center">
            <div style={{width: "10rem", flexShrink: 0}}>
                <IconButton icon={<ThumbsDownIcon size="l"/>} onClick={() => handleButtonClick('left')} expanded={preSwipeDirection === "left"} label="meh" className="float-right" activeColor="#4A841C"/>
            </div>
            <div style={{flexShrink: 0}}>
                <IconButton icon={<LightningIcon size="l"/>} onClick={() => handleButtonClick('up')} activeColor="#FFAF0D"/>
            </div>
            <div style={{width: "10rem", flexShrink: 0}}>
                <IconButton icon={<HeartFilled/>} onClick={() => handleButtonClick('right')} expanded={preSwipeDirection === "right"} label="love it" reversed={true} activeColor="#FF0200"/>
            </div>

            <animated.div style={awesomeAnimation} className="absolute rounded-full w-48 h-12 bg-[#FDC703] bottom-20 z-10 m-auto">
                <div className="absolute rounded-full bg-white py-4 px-8 font-semibold bottom-[8px] text-xl w-full h-full z-20 text-center leading-[1rem] border border-yellow-500">
                    AWESOME!
                </div>
            </animated.div>
        </div>
    </div>;
};

function resolveTransform(direction: Direction | null): string {
    switch (direction) {
        case 'right':
            return 'translateX(200vw) translateY(0px) rotate(15deg)';
        case 'left':
            return 'translateX(-200vw) translateY(0px) rotate(-15deg)';
        case 'up':
            return 'translateX(0px) translateY(-100vh) rotate(0deg)';
        case 'down':
            return 'translateX(0px) translateY(100vh) rotate(0deg)';
        default:
            return 'translateX(0px) translateY(0px) rotate(0deg)';
    }
}
